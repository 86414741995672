import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const about = () => (
  <Layout>
    <SEO title="About Page" />
    <h1>About Page</h1>
  </Layout>
)

export default about
